import axios from "axios";
import { checkObjectValuesSafeString } from "../helpers/validationHelper";

const ENABLE_LOGS = false;
const LOG_REQ = false;
const LOG_RES = false;
//Creating base variables
let BASE_URL, HEADERS, FRONT_END_BASE_URL, DESK_URL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // Development
  BASE_URL = process.env.NEXT_PUBLIC_APIURL;
  HEADERS = {
    "Content-Type": "application/json",
  };
  FRONT_END_BASE_URL = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL;
  DESK_URL = process.env.NEXT_PUBLIC_DESKURL;
} else {
  // Production
  BASE_URL = process.env.NEXT_PUBLIC_APIURL;
  HEADERS = {
    "Content-Type": "application/json",
  };
  FRONT_END_BASE_URL = process.env.NEXT_PUBLIC_FRONT_END_BASE_URL;
  DESK_URL = process.env.NEXT_PUBLIC_DESKURL;
}

var lastChar = FRONT_END_BASE_URL.slice(-1);
if (lastChar == "/") {
  FRONT_END_BASE_URL = FRONT_END_BASE_URL.slice(0, -1);
}

/* Front-End API */
const nextAPI = axios.create({
  baseURL: FRONT_END_BASE_URL + "/api",
  headers: HEADERS,
});

const userExistsAPI = axios.create({
  baseURL: BASE_URL + "/api/admin/sso/users/exists",
  headers: HEADERS,
});

/* Back-End API */
const authAPI = axios.create({
  baseURL: BASE_URL + "/api/auth/login",
  headers: HEADERS,
});

const customersAPI = axios.create({
  baseURL: BASE_URL + "/api/customer",
  headers: HEADERS,
});

const customerDocumentsAPI = axios.create({
  baseURL: BASE_URL + "/api/customer_documents",
  headers: HEADERS,
});

const configsAPI = axios.create({
  baseURL: BASE_URL + "/api/configs",
  headers: HEADERS,
});

/*
const documentsAPI = axios.create({
  baseURL: DESK_URL + "/api/documents",
  headers: HEADERS,
});*/

const privacyDocumentsAPI = axios.create({
  baseURL: BASE_URL + "/api/privacy_documents",
  headers: HEADERS,
});

const privacyDocumentsConsensusesAPI = axios.create({
  baseURL: BASE_URL + "/api/privacy_documents_consensuses",
  headers: HEADERS,
});

const ticketsAPI = axios.create({
  baseURL: BASE_URL + "/api/tickets",
  headers: HEADERS,
});

const calendarsApi = axios.create({
  baseURL: BASE_URL + "/api/calendars",
  headers: HEADERS,
});

const productsApi = axios.create({
  baseURL: BASE_URL + "/api/products",
  headers: HEADERS,
});

const productCategoriesApi = axios.create({
  baseURL: BASE_URL + "/api/product_categories",
  headers: HEADERS,
});

const merchandiseApi = axios.create({
  baseURL: BASE_URL + "/api/products/merchandise",
  headers: HEADERS,
});

const serviceApi = axios.create({
  baseURL: BASE_URL + "/api/products/service",
  headers: HEADERS,
});

const ordersAPI = axios.create({
  baseURL: BASE_URL + "/api/orders",
  headers: HEADERS,
});

const prepareAPI = axios.create({
  baseURL: BASE_URL + "/api/orders/prepare",
  headers: HEADERS,
});

const addPaymentInfoAPI = axios.create({
  baseURL: BASE_URL + "/api/orders/payments",
  headers: HEADERS,
});

const confirmAPI = axios.create({
  baseURL: BASE_URL + "/api/orders/confirm",
  headers: HEADERS,
});

const ownersApi = axios.create({
  baseURL: BASE_URL + "/api/owners",
  headers: HEADERS,
});

const countriesAPI = axios.create({
  baseURL: BASE_URL + "/api/addresses/country",
  headers: HEADERS,
});

const provincesAPI = axios.create({
  baseURL: BASE_URL + "/api/addresses/province",
  headers: HEADERS,
});

const citiesAPI = axios.create({
  baseURL: BASE_URL + "/api/addresses/city",
  headers: HEADERS,
});

const uploadApi = axios.create({
  baseURL: BASE_URL + "/api/upload",
  headers: HEADERS,
});

const adminApi = axios.create({
  baseURL: BASE_URL + "/api/admin/sso",
  headers: HEADERS,
});

const reservationApi = axios.create({
  baseURL: BASE_URL + "/api/reservations",
  headers: HEADERS,
});

const reportApi = axios.create({
  baseURL: BASE_URL + "/api/report",
  headers: HEADERS,
});

const notificationAPI = axios.create({
  baseURL: BASE_URL + "/api/notification",
  headers: HEADERS,
});

const areaAPI = axios.create({
  baseURL: BASE_URL + "/api/area",
  headers: HEADERS,
});

const builderAPI = axios.create({
  baseURL: BASE_URL + "/api/form_builder",
  headers: HEADERS,
});

const bbEventsAPI = axios.create({
  baseURL: BASE_URL + "/api/blackbox_event_logs",
  headers: HEADERS,
});

const communicatorAPI = axios.create({
  baseURL: BASE_URL + "/api/communicator",
  headers: HEADERS,
});

const gcfAPI = axios.create({
  baseURL: BASE_URL + "/api/gcf",
  headers: HEADERS,
});

const nifiApi = axios.create({
  baseURL: BASE_URL + "/api/nifi",
  headers: HEADERS,
});

const previewApi = axios.create({
  baseURL: `http://localhost:3001`,
  headers: HEADERS,
});

const axiosAPIConnectors = [
  nextAPI,
  authAPI,
  customersAPI,
  customerDocumentsAPI,
  configsAPI,
  privacyDocumentsAPI,
  privacyDocumentsConsensusesAPI,
  ticketsAPI,
  ordersAPI,
  productsApi,
  productCategoriesApi,
  merchandiseApi,
  serviceApi,
  prepareAPI,
  addPaymentInfoAPI,
  confirmAPI,
  ownersApi,
  provincesAPI,
  countriesAPI,
  citiesAPI,
  uploadApi,
  adminApi,
  calendarsApi,
  reservationApi,
  reportApi,
  notificationAPI,
  areaAPI,
  builderAPI,
  bbEventsAPI,
  communicatorAPI,
  gcfAPI,
  nifiApi,
  previewApi,
];

for (var axiosAPIConnectorIndex = 0; axiosAPIConnectorIndex < axiosAPIConnectors.length; axiosAPIConnectorIndex++) {
  axiosAPIConnectors[axiosAPIConnectorIndex].interceptors.request.use(
    function (config) {
      let trackingForLoggedUser = null;
      if (
        typeof window !== "undefined" &&
        window.localStorage.getItem("customer") &&
        typeof window.localStorage.getItem("customer") !== "undefined" &&
        window.localStorage.getItem("customer") !== "undefined"
      ) {
        trackingForLoggedUser = window.localStorage.getItem("customer");
        trackingForLoggedUser = JSON.parse(trackingForLoggedUser);
      }
      config.params = config.params || {};
      config.params["logged_name"] = trackingForLoggedUser?.company_name;
      config.params["logged_mail"] = trackingForLoggedUser?.email;
      config.params["logged_customer_code"] = trackingForLoggedUser?.code;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

for (var axiosAPIConnectorIndex = 0; axiosAPIConnectorIndex < axiosAPIConnectors.length; axiosAPIConnectorIndex++) {
  axiosAPIConnectors[axiosAPIConnectorIndex].interceptors.response.use(
    function (response) {
      if (!response.status) {
        response.status = 500;
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export {
  ENABLE_LOGS,
  nextAPI,
  authAPI,
  customersAPI,
  customerDocumentsAPI,
  configsAPI,
  privacyDocumentsAPI,
  privacyDocumentsConsensusesAPI,
  ticketsAPI,
  ordersAPI,
  productsApi,
  productCategoriesApi,
  merchandiseApi,
  serviceApi,
  prepareAPI,
  addPaymentInfoAPI,
  confirmAPI,
  ownersApi,
  provincesAPI,
  countriesAPI,
  citiesAPI,
  uploadApi,
  adminApi,
  calendarsApi,
  reservationApi,
  reportApi,
  notificationAPI,
  areaAPI,
  userExistsAPI,
  builderAPI,
  bbEventsAPI,
  communicatorAPI,
  gcfAPI,
  nifiApi,
  previewApi,
};
