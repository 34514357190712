import useTranslation from "next-translate/useTranslation";

function Dropdown({
  label = null,
  placeholder = "",
  onChange = () => {},
  onBlur = () => {},
  value,
  options = [],
  css = "",
  style = {},
  compCss = "",
  labelCss = "",
  error = false,
  optionDisabled = false,
  none = false,
  multipleSelectIndex = false,
  forcedClassName = false,
  labelBottomMargin = "mb-2",
  required = false,
  ...rest
}) {
  const { t } = useTranslation("common");

  let dropDownClasses = none
    ? `w-full p-3 mr-10 border-none outline-none bg-transparent ${css}`
    : `form-select w-full rounded-md border p-3 transition-colors cursor-pointer pr-10 ${
        error ? "focus:border-app-redLight border-app-redLight" : "focus:border-app-orange"
      } disabled:cursor-not-allowed ${css}`;
  if (forcedClassName) {
    dropDownClasses = forcedClassName;
  }

  return (
    <div className={`relative flex flex-col ${compCss}`}>
      {label && (
        <span
          className={` font-normal ${labelBottomMargin} ${labelCss} ${
            error ? "text-app-redLight" : "text-app-mediumgrey"
          }`}
        >
          {label}
          {required ? " *" : ""}
        </span>
      )}
      <select
        className={dropDownClasses}
        onChange={(e) => onChange(e.target.value, multipleSelectIndex)}
        onBlur={(e) => onBlur(e.target.value)}
        value={value}
        placeholder={placeholder}
        style={{ ...style }}
        {...rest}
      >
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map((o, index) => (
          <option value={o?.value ?? o?.name ?? o?.label ?? o} key={index} disabled={optionDisabled}>
            {o?.label ?? o?.name ?? o}
          </option>
        ))}
      </select>
      {error && <span className={`absolute top-20 ${labelBottomMargin}  text-app-redLight text-xs`}>{error}</span>}
    </div>
  );
}

export default Dropdown;
